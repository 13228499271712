import {nextTick, onUnmounted, watch} from 'vue'
import {scrollToId} from '@/helpers'
import {router} from '@inertiajs/vue3'

export function scrollToFirstError(event) {

    nextTick(() => {
        scrollToId(Object.keys(event.detail.errors)[0])
    })
}

export function confirmLeavePageWithChanges(form, cancelUrlPath) {

    // Very ugly workaround to prevent multiple confirmations.
    let confirmed = [];
    let already_canceled = false;

    onUnmounted(
        router.on('before', (event) => {

            if (
                ! confirmed.includes(event.detail.visit.url.toString()) &&
                form.isDirty &&
                event.detail.visit.method === 'get' &&
                `${window.location.protocol}//${window.location.host}${cancelUrlPath}` !== event.detail.visit.url.toString()
            ) {

                if (already_canceled) {

                    event.preventDefault();
                    already_canceled = false;
                    return;
                }

                if (! confirm('Auf der Seite gibt es nicht gespeicherte Änderungen. Trotzdem verlassen?')) {
                    event.preventDefault();
                    already_canceled = true;
                } else {
                    confirmed.push(event.detail.visit.url.toString());
                }
            }
        })
    )

}
