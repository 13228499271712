<template>
    <div class="min-h-screen w-full" ref="el">
        <div class="w-full" :class="user?.id && 'border-bottom-1 border-bg'">

            <div id="navbar" class="content flex align-items-center">
                <div class="h-full flex align-items-center">
                    <img v-if="user?.id" alt="logo" src="/images/logo-icon.svg" height="40" class="mr-4">
                    <img v-else alt="logo" src="/images/logo-schriftzug.svg" height="40" class="mr-3">
                </div>

                <div id="navbar-entries" class="flex w-full h-full align-items-center flex-nowrap gap-7 overflow-y-scroll">
                    <div class="flex nav gap-3 lg:gap-4 h-full align-items-end">
                        <Link v-for="item in navEntries" :href="item.url"
                              class="nav-item no-underline transition-all transition-duration-400 hover:text-800"
                              :class="isCurrent(item) ? 'current font-semibold text-800': 'text-700'"
                              style="letter-spacing: .03rem">
                            {{item.label}}
                        </Link>
                    </div>

                    <div class="ml-auto mt-2">

                        <div v-if="user?.id" class="flex">

                            <LinkButton url="/settings/user"
                                        :button-class="settingsButtonClass"
                                        icon="pi pi-cog" />

                            <Button class="ml-2 p-button-text p-button-rounded p-button-secondary"
                                    @click="confirmLogout"
                                    icon="pi pi-sign-out"/>
                        </div>

                        <div v-else>
                            <LinkButton v-if="currentUrl.startsWith('/registrieren')"
                                        url="/anmelden"
                                        label="Anmelden"
                                        icon="pi pi-user"
                                        size="small"
                                        severity="secondary"
                                        button-class="p-button-text p-button-rounded" />

                            <LinkButton v-if="currentUrl === '/anmelden'"
                                        url="/registrieren"
                                        label="Registrieren"
                                        icon="pi pi-user-plus"
                                        size="small"
                                        severity="secondary"
                                        button-class="p-button-text p-button-rounded" />

                        </div>
                    </div>
                </div>

            </div>


        </div>

        <div id="content">
            <slot />
        </div>

        <Head>
            <title>{{ title }}</title>
        </Head>

        <Flashes />
        <ConfirmDialog />

      <Dropdown
          v-if="user?.id && (impersonate?.can || impersonate?.impersonating)"
          class="fixed bg-white-alpha-80"
          style="bottom: 1rem; left: 1rem"
          v-model="instance"
          :placeholder="user.email"
          :options="instances"
          :show-clear="true"
          :auto-filter-focus="true"
          :auto-option-focus="true"
          :filter="true"
          option-label="email" />

    </div>
</template>

<script setup>
import {Head} from '@inertiajs/vue3'
import {computed, inject, provide} from 'vue'
import Flashes from '@/Partials/Flashes.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import Button from 'primevue/button'
import { router, usePage } from '@inertiajs/vue3'
import LinkButton from '@Components/Buttons/LinkButton.vue'
import {useBreakpoints} from '@vueuse/core'
import Dropdown from 'primevue/dropdown'

const modules = inject('modules')
const user = inject('user')
const demo = inject('demo')

const breakpoints = useBreakpoints({
  md: 768,
  lg: 992,
  xl: 1200,
});

const isViewXl = breakpoints.greaterOrEqual('xl');
const isViewMd = breakpoints.greaterOrEqual('md');

const currentUrl = computed(() => usePage().url);

provide('breakpoints', breakpoints);
provide('isViewXl', isViewXl);
provide('isViewMd', isViewMd);

const navEntries = computed(() => {

  if (!user.value?.id) {
    return [];
  }

  return [
    {
      label: 'Kunden',
      key: 'client',
      url: '/clients',
    },
    {
      label: 'Rechnungen',
      key: 'invoic',
      url: '/invoices',
    },
    {
      label: 'Buchungen',
      key: 'booking',
      url: '/bookings',
    },
    {
      label: 'Berichte',
      key: 'report',
      url: '/reports',
    }
  ];
})

const title = computed(() => usePage().props.title)

const calendarButtonClass = computed(() => {

  let cls = 'mr-2 p-button-text p-button-rounded p-button-secondary'

  if (usePage().url.startsWith('/calendar')) {
    cls = cls + " active"
  }

  return cls
})

const settingsButtonClass = computed(() => {

  let cls = 'mr-2 p-button-text p-button-rounded p-button-secondary'

  if (usePage().url.startsWith('/settings')) {
    cls = cls + " active"
  }

  return cls
})

const navbar = $ref()

function isCurrent(entry) {
  return usePage().url.startsWith(`/${entry.key}`) || usePage().url.startsWith(`${entry.url}`)
}

function confirmLogout() {
  if(window.confirm('Wirklich abmelden?')) {
    router.get('/logout')
  }
}

const impersonate = computed(() => usePage().props.impersonate);
const instances = computed(() => usePage().props.impersonate.instances);
const instance = computed({
  get() {
    return instances.value.find((instance) => user.value?.id === instance.id);
  },
  set(instance) {
    if (instance?.id) {
      window.location.href = `/impersonate/${instance.id}`;
    } else {
      window.location.href = '/impersonate/leave';
    }
  }
});

</script>

<style lang="scss" scoped>

#navbar {

  height: 60px;

  #navbar-entries {

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */


    a.nav-item {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      //border-bottom: 3px solid transparent;
      //
      //&.current {
      //  border-color: $primary;
      //}

    }
  }

}

#content {
  padding-bottom: 1rem;
}

</style>
